<template>
  <v-container fluid class="content-wrapper">
    <div class="row match-height">
      <h4 class="fw-600 text-mb-2">
        {{ $t("SigninUserAndRole.title_role") }}
      </h4>
      <div class="col-12 pb-1 px-5">
        <form class="form form-horizontal">
          <div class="form-body">
            <div class="row">
              <label
                class="col-md-auto px-0 label-control text-left"
                for="projectinput1"
                >Name :
              </label>
              <div class="col px-0 px-md-1">
                <input
                  type="text"
                  id="projectinput1"
                  class="form-control"
                  placeholder="Super admin"
                  name="fname"
                />
              </div>
            </div>
            <div class="row mt-2">
              <label
                class="col-md-auto px-0 label-control text-left"
                for="projectinput1"
                >Status :
              </label>
              <div class="col px-0 px-md-1">
                <div class="form-group">
                  <select class="form-control" id="">
                    <option>Enable</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-12">
        <!-- Recent Booking -->
        <div class="row">
          <div id="recent-transactions" class="col-12">
            <div class="card">
              <div class="card-content p-2">
                <div class="table-responsive">
                  <table
                    id="recent-orders"
                    class="
                      table table-white-space
                      row-grouping
                      display
                      no-wrap
                      icheck
                      table-middle
                      text-center
                      dataTable
                    "
                    role="grid"
                    aria-describedby="users-contacts_info"
                  >
                    <thead class="">
                      <tr role="row">
                        <!--<th class="sorting border-top-0 text-center" tabindex="0" aria-controls="users-contacts" rowspan="1" colspan="1" aria-label=": activate to sort column ascending" style="position: inherit;">
																<div class="custom-control custom-checkbox text-uppercase">
																	<input type="checkbox" class="custom-control-input" checked id="checkboxsmallall">
																	<label class="custom-control-label" for="checkboxsmallall">All</label>
																</div>
															</th>-->
                        <th
                          class="border-top-0 text-center text-uppercase"
                          tabindex="0"
                          aria-controls="users-contacts"
                          rowspan="1"
                          colspan="1"
                          aria-sort="descending"
                          aria-label="Name: activate to sort column ascending"
                          style="position: inherit"
                        ></th>
                        <th
                          class="border-top-0 text-center text-uppercase"
                          tabindex="0"
                          aria-controls="users-contacts"
                          rowspan="1"
                          colspan="1"
                          aria-sort="descending"
                          aria-label="Name: activate to sort column ascending"
                          style=""
                        >
                          Add_data
                        </th>
                        <th
                          class="border-top-0 text-center text-uppercase"
                          tabindex="0"
                          aria-controls="users-contacts"
                          rowspan="1"
                          colspan="1"
                          aria-label="Email: activate to sort column ascending"
                          style=""
                        >
                          Edit_data
                        </th>
                        <th
                          class="border-top-0 text-center text-uppercase"
                          tabindex="0"
                          aria-controls="users-contacts"
                          rowspan="1"
                          colspan="1"
                          aria-label="Phone: activate to sort column ascending"
                          style=""
                        >
                          Delete_data
                        </th>
                        <th
                          class="border-top-0 text-center text-uppercase"
                          tabindex="0"
                          aria-controls="users-contacts"
                          rowspan="1"
                          colspan="1"
                          aria-label="Phone: activate to sort column ascending"
                          style=""
                        >
                          View
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr role="row" class="">
                        <td class="text-left">Home page</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox1"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox1"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox2"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox2"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox3"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox3"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox4"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox4"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Banner</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox5"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox5"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox6"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox6"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox7"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox7"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox8"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox8"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Key messages</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox9"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox9"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox10"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox10"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox11"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox11"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox12"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox12"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Social</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox13"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox13"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox14"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox14"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox15"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox15"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox16"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox16"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Services</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox17"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox17"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox18"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox18"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox19"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox19"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox20"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox20"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Services Category</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox21"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox21"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox22"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox22"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox23"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox23"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox24"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox24"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Services question</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox25"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox25"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox26"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox26"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox27"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox27"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox28"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox28"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Services List</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox29"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox29"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox30"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox30"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox31"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox31"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox32"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox32"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Products</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox33"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox33"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox34"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox34"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox35"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox35"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox36"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox36"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Products Category</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox37"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox37"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox38"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox38"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox39"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox39"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox40"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox40"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Products Subcategory</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox41"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox41"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox42"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox42"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox43"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox43"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox44"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox44"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Products Question</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox45"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox45"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox46"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox46"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox47"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox47"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox48"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox48"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Products Detail</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox49"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox49"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox50"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox50"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox51"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox51"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox52"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox52"
                            ></label>
                          </div>
                        </td>
                      </tr>
                      <tr role="row" class="">
                        <td class="text-left">Contacts</td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox53"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox53"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox54"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox54"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox55"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox55"
                            ></label>
                          </div>
                        </td>
                        <td style="text-align: center">
                          <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              checked=""
                              id="checkbox56"
                            />
                            <label
                              class="custom-control-label"
                              for="checkbox56"
                            ></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/ Recent Booking -->
      </div>
    </div>
  </v-container>
</template>

<script>
import { EventBus } from "@/EventBus";
import swal from "sweetalert";

export default {
  name: "SigninRoleScreen",
  metaInfo: {
    title: "Role",
    titleTemplate: "%s | Aigent",
  },
};
</script>

<style scoped>
@import "../../../styles/signin/holiday.css";
@import "../../../styles/signin/rateplan.css";
</style>

<style lang="scss" scoped>
@import "../../../styles/bootstrap/global.css";
@import "../../../styles/signin/setting.css";

.border-pink-focus {
  border-color: var(--pink) !important;
}
</style>
